// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo({ secondary, small, ...rest }) {
  let logoSrc = '';

  if (small) {
    logoSrc = '/static/brand/logo_single.svg';
  } else if (secondary) {
    logoSrc = '/static/brand/logo_full_white.svg';
  } else {
    logoSrc = '/static/brand/logo_full.svg';
  }

  return <Box component="img" alt="medigrant logo" src={logoSrc} height={40} {...rest} />;
}
