import { useTranslation } from 'react-i18next';
import htmr from 'htmr';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'ko',
    label: '한국어',
    icon: '/static/icons/ic_flags_kr.svg',
    iconlight: '/static/icons/ic_flags_kr_light.svg',
  },
  {
    value: 'en',
    label: 'English',
    icon: '/static/icons/ic_flags_us.svg',
    iconlight: '/static/icons/ic_flags_us_light.svg',
  },
];

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translation } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
    document.documentElement.setAttribute('lang', newlang);
  };

  const t = (str) => htmr(translation(str).replace(/(?:\r\n|\r|\n)/g, '<br />'));

  return {
    onChangeLang: handleChangeLanguage,
    t,
    currentLang,
    allLang: LANGS,
  };
}
