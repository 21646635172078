import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import roundGrain from '@iconify/icons-ic/round-grain';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
// routes
import { PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/'
  },
  {
    title: 'About Us',
    icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
    path: PATH_PAGE.about
  },
  {
    title: 'Portfolio',
    icon: <Icon icon={fileFill} {...ICON_SIZE} />,
    path: PATH_PAGE.portfolio
  },
  // {
  //   title: 'Services',
  //   path: PATH_PAGE.services,
  //   icon: <Icon icon={fileFill} {...ICON_SIZE} />
  // },
  // {
  //   title: 'MediioTech',
  //   icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
  //   path: PATH_PAGE.technologies
  // },

  {
    title: 'Contact Us',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.contact
  },

  {
    title: 'Careers',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.careers
  }
];

export default menuConfig;
